<template>
  <div>
    <div class="header-secondary title-tabs header-title d-none d-sm-block">
      Success Response
    </div>
    <div class="pt-2 container">
      <TextEditorsTiny
        textFloat="Description"
        placeholder="Description"
        :value="form.response_success"
        @onDataChange="(val) => (form.response_success = val)"
        v-model="form.response_success"
      />
    </div>
    <div class="header-secondary title-tabs header-title d-none d-sm-block">
      Fail Response
    </div>
    <div class="container py-2">
      <InputText
        textFloat="Description"
        placeholder="Description"
        type="text"
        name="Description"
        v-model="form.response_fail"
      />
    </div>
    <div class="header-secondary title-tabs header-title d-none d-sm-block">
      Theme
    </div>
    <div class="container py-2">
      <div class="mb-3">
        <b-form-group label="Layout" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="form.layout_id"
            :options="[
              { value: 0, text: 'Standard' },
              { value: 1, text: 'Simple' },
            ]"
            :aria-describedby="ariaDescribedby"
            name="line_layout"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="mb-3">
        <ColorPicker
          textFloat="Title Color"
          v-model="form.title_color"
          :value="form.title_color"
          name="primary_color"
        />
      </div>
      <div class="mb-3">
        <ColorPicker
          textFloat="Background Color"
          v-model="form.background_color"
          :value="form.background_color"
          name="primary_color"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/inputs/InputColorPicker";
export default {
  components: { ColorPicker },
  props: {
    form: {
      required: true,
    },
  },
};
</script>

<style></style>
